<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Acceder</h1>
                  <p class="text-muted">Iniciar sesión en su cuenta</p>
                  <CInput
                    v-model="user.username"
                    placeholder="Username"
                    autocomplete="username email"
                    required
                    valid-feedback="Formato de correo electrónico correcto"
                    invalid-feedback="Ingrese su correo electrónico"
                    :is-valid="emailValidator"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    v-model="user.password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    required
                    valid-feedback="Requisitos mínimos de password cumplidos"
                    invalid-feedback="Requisitos mínimos de password insuficientes.  Debe contener al menos doce (12) caracteres y debe combinar caracteres de distinto tipo (mayúsculas, minúsculas, números y símbolos)"
                    :is-valid="passwordValidator"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <div class="text-center" v-if="loading">
                        <div class="spinner-border" role="status"></div>
                      </div>
                      <CButton
                        v-if="!loading"
                        color="primary"
                        class="px-4"
                        @click="handleLogin()"
                        :disabled="!formValidator()"
                        >Ingresar</CButton
                      >
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0" @click="irRecuperarClave()">Recuperar Clave</CButton>
                      <!-- <CButton color="link" class="d-lg-none">Register now!</CButton> -->
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
              <div class="alert alert-danger" v-if="errorLogin">
                <p>Se ha presentado un error, no se pudo iniciar sesión con esas credenciales</p>
              </div>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <img
                  src="key.png"
                  class="c-avatar-img"
                />
                <p></p>
                <h3 class="secondary-color">Master Suite</h3>
                <!-- <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton> -->
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import User from "@/models/user";
import validationField from "@/util/validationField"
import AuthService from '@/services/auth.service'
import UserService from '@/services/user.service'

export default {
  name: "Login",
  data() {
    return {
      user: new User(undefined, undefined, undefined),
      loading: false,
      message: "",
      errorLogin: false
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    esInvitado() {
      return this.user.username == 'invitado1@eco.com.co' ||
      this.user.username == 'invitado2@ceco.com.co'
    },
    validator (val) {
      console.log(val)
      return val===undefined?  null: val? val.length >= 4 : false
    },
    emailValidator (val) {
      return val===undefined?  null: val? validationField('email', val) : false
    },
    passwordValidator (val) {
      return val===undefined?  null: val? validationField('password', val) : false
    },
    formValidator() {
      return this.emailValidator(this.user.username) && this.passwordValidator(this.user.password) || this.esInvitado()
    },
    irRecuperarClave(){
      this.$router.push("/pages/recuperar-clave");
    },
    handleLogin() {
      this.loading = true
      this.errorLogin = false
      //if(this.esInvitado)
      //  this.user.password = ''
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false
          return
        }

        if (this.formValidator()) {
          this.$store.dispatch("auth/login", this.user).then(
            (response) => {
 
              UserService.getItem(AuthService.parseJwt(response.access_token).sub).then(
                (response) => {
                  localStorage.setItem("user_auth", JSON.stringify(response.data))
                  this.$router.push("/dashboard")
                  return response.data
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                  this.loading = false;
                }
              );

            },
            (error) => {
              this.loading = false
              this.errorLogin = true
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    }
  },
};
</script>
